import {Button} from '../components/Button';
import {Icons} from '../components/Icons';
import style from './AllAboutYouForm.scss.json';
import './AllAboutYouForm.scss';
import {InputField} from 'lincd-input/lib/components/InputField';
import {SelectDropdown} from '../components/SelectDropdown';
import {useAuth} from 'lincd-auth/lib/hooks/useAuth';
import {Person} from 'profile-plus/lib/shapes/Person';
import {ClientAccount} from '../shapes/ClientAccount';
import {DateInputField} from 'lincd-input/lib/components/DateInputField';
import {Checkbox} from './Checkbox';
import {useState} from 'react';
import {TextField} from 'lincd-input/lib/components/TextField';
import {packageName} from '../package';
import {Server} from 'lincd-server-utils/lib/utils/Server';
import {useNavigate} from 'react-router-dom';
import {ROUTES} from '../routes';
import {ProviderAccount} from '../shapes/ProviderAccount';
import {cl} from 'lincd/lib/utils/ClassNames';
import {Capacitor} from '@capacitor/core';
import {DatePicker} from 'react-date-picker';

interface AllAboutYouFormProps {
  onSubmit?;
  onSubmitLabel?: string;
}

const AllAboutYouForm = ({onSubmit, onSubmitLabel}: AllAboutYouFormProps) => {
  const auth = useAuth<Person, ClientAccount>();
  const user = auth.user;
  const navigate = useNavigate();
  const [claimsToBeProvider, setClaimsToBeProvider] = useState<boolean>(false);
  const [code, setCode] = useState<string>('');
  const [profilePicture, setProfilePicture] = useState<string>('');
  const [codeErrorMessage, setCodeErrorMessage] = useState<string>('');
  const [noCodeProvider, setNoCodeProvider] = useState<boolean>(false);

  // trigger a re-render for datepicker
  const [bool, setBool] = useState(false);
  const forceUpdate = () => setBool(!bool);

  const handleIsProvider = (e) => {
    const target = e.target.checked;
    setClaimsToBeProvider(target);
    setCodeErrorMessage('');
  };

  const handleCode = (e) => {
    const target = e.target.value;
    setCode(target);
  };

  const onCheckProviderCode = async () => {
    console.log('onCheckProviderCode');
    try {
      const result = await Server.call(packageName, 'checkProviderCode', code);
      if (result) {
        //update the local auth instance with the newly returned authentication
        //this will update all components that useAuth();
        const update = auth.updateAuth(
          result.auth,
          result.accessToken,
          result.refreshToken,
        );

        if (update) {
          //navigate to the next step
          // TODO: for now disable biometric auth
          navigate(ROUTES.biometric.path);
          // navigate(ROUTES.allAboutProvider.path);
        } else {
          console.warn('User not updated with new auth.');
        }
      } else {
        console.warn('The code you provided is not valid. Please try again');
        setCodeErrorMessage(
          'The code you provided is not valid. Please try again',
        );
      }
    } catch (err) {
      console.warn('Check provider code is failed ', err);
    }
  };

  const onCheckNoProviderCode = async () => {
    try {
      const result = await Server.call(packageName, 'checkNoProviderCode');
      if (result) {
        const update = auth.updateAuth(
          result.auth,
          result.accessToken,
          result.refreshToken,
        );
        if (update) {
          //navigate to the next step
          // TODO: for now disable biometric auth
          // navigate(ROUTES.biometric.path);
          navigate(ROUTES.allAboutProvider.path);
        } else {
          console.warn('User not updated with new auth.');
        }
      }
    } catch (err) {
      console.warn('Check provider account is failed ', err);
    }
  };

  // handle checkbox provider without code
  const handleNoCodeProvider = (e) => {
    const target = e.target.checked;
    setNoCodeProvider(target);
    setCodeErrorMessage('');
  };

  const onSubmitHandler = () => {
    // if the user claims to be a provider, check the code
    if (claimsToBeProvider && !noCodeProvider) {
      onCheckProviderCode();
      // if the user claims to be a provider, but does not have a code, check the no code provider
    } else if (noCodeProvider) {
      onCheckNoProviderCode();
    } else {
      if (onSubmit) {
        onSubmit();
      }
    }
  };

  const onDatepickerChange = (date: Date) => {
    user.birthDate = date;
    forceUpdate();
  };

  const DatePickerField = ({label, labelIcon}) => {
    return Capacitor.getPlatform() === 'web' ? (
      <div>
        {label && (
          <div className={style.labelContainer}>
            {labelIcon && labelIcon}
            <label>{label}</label>
          </div>
        )}
        <div className={style.input}>
          <DatePicker
            className={style.datepicker}
            calendarClassName={style.calendar}
            defaultValue={user.birthDate}
            value={user.birthDate}
            onChange={(date: Date) => {
              onDatepickerChange(date);
            }}
            maxDate={new Date()}
            format="dd/MM/yyyy"
            dayPlaceholder="dd"
            monthPlaceholder="mm"
            yearPlaceholder="yyyy"
            calendarIcon={null}
          />
        </div>
      </div>
    ) : (
      <DateInputField
        name="date-of-birth"
        label={label}
        className={cl(
          style.input,
          Capacitor.getPlatform() === 'web' ? style.dateInput : '',
        )}
        of={user}
        valueProperty="birthDate"
        loadingImageClassName={style.loadingInput}
        labelIcon={labelIcon}
      />
    );
  };

  return (
    <>
      <form className={style.container__form}>
        <div className={style.form__inputContainer}>
          <InputField
            type="text"
            name="first-name"
            placeholder="First name"
            label="First name"
            className={style.input}
            of={user}
            valueProperty="givenName"
            loadingImageClassName={style.loadingInput}
            labelIcon={
              <img
                src="/images/profile/male_user.png"
                alt="user icon"
                height="20"
                width="20"
              />
            }
          />
          <InputField
            type="text"
            name="last-name"
            placeholder="Last name"
            label="Last name"
            className={style.input}
            of={user}
            valueProperty="familyName"
            loadingImageClassName={style.loadingInput}
            labelIcon={
              <img
                src="/images/profile/male_user.png"
                alt="user icon"
                height="20"
                width="20"
              />
            }
          />
          <DatePickerField
            label={'Date of birth (optional)'}
            labelIcon={
              <img
                src="/images/profile/calendar.png"
                alt="calendar icon"
                height="20"
                width="20"
              />
            }
          />
          <SelectDropdown
            name="gender"
            options={['Male', 'Female']}
            placeholder="Gender"
            label="Gender (optional)"
            className={style.selectOption}
            of={user}
            defaultValue={'Select'}
            property="gender"
            labelIcon={
              <img
                src="/images/profile/gender.png"
                alt="gender icon"
                height="20"
                width="20"
              />
            }
          />
          <InputField
            type="number"
            name="whatsapp-number"
            placeholder="Whatsapp number"
            label="Whatsapp number (optional)"
            className={style.input}
            of={user}
            valueProperty="telephone"
            loadingImageClassName={style.loadingInput}
            labelIcon={
              <img
                src="/images/profile/whatsapp.png"
                alt="whatsapp icon"
                height="20"
                width="20"
              />
            }
          />
          {!(auth.userAccount instanceof ProviderAccount) && (
            <InputField
              type="text"
              name="bio"
              placeholder="Bio"
              label={
                auth.userAccount instanceof ProviderAccount
                  ? 'Personal Bio'
                  : 'Bio'
              }
              className={cl(style.input, style.textarea)}
              of={user}
              valueProperty="description"
              multiline={true}
              loadingImageClassName={style.loadingInput}
            />
          )}
          {!(auth.userAccount instanceof ProviderAccount) && (
            <div>
              <Checkbox
                title={'I am a provider'}
                checked={claimsToBeProvider}
                onChange={handleIsProvider}
              />
            </div>
          )}
          {claimsToBeProvider && (
            <div>
              <TextField
                onChange={(e) => handleCode(e)}
                type="text"
                className={cl(
                  style.input,
                  codeErrorMessage && style.errorText,
                  noCodeProvider && style.disabledInput,
                )}
                placeholder={'Enter provider code'}
                helperText={codeErrorMessage}
                disabled={noCodeProvider}
              />
              {/* <p>
                <a
                  href="https://emergentwellness.com/practitioner-contact"
                  target="_blank"
                  rel="noreferrer"
                >
                  Or click here to apply for provider code
                </a>
              </p> */}
              <div className={style.noCodeCheckbox}>
                <Checkbox
                  title={"I don't have a provider code yet"}
                  checked={noCodeProvider}
                  onChange={handleNoCodeProvider}
                />
              </div>
            </div>
          )}
        </div>
      </form>
      <Button
        color="primary"
        endIcon={!onSubmitLabel && <Icons.RightArrow color="white" />}
        className={style.primaryButton}
        onClick={onSubmitHandler}
      >
        {onSubmitLabel || 'Continue'}
      </Button>
    </>
  );
};

export default AllAboutYouForm;
