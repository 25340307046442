import React, {useEffect, useState} from 'react';
import './BiometricAuth.scss';
import style from './BiometricAuth.scss.json';
import {DefaultLayout} from '../layout/DefaultLayout';
import {Button} from 'lincd-mui-base/lib/components/Button';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {ROUTES} from '../routes';
import {useAuth} from 'lincd-auth/lib/hooks/useAuth';
import {Person} from 'lincd-schema/lib/shapes/Person';
import {ClientAccount} from '../shapes/ClientAccount';
import {
  EnableAuthenticatorButton,
  performBiometricVerification,
} from 'lincd-authenticator/src/components/EnableAuthenticatorButton';
import {AuthenticatorConfig} from 'lincd-authenticator/src/shapes/AuthenticatorConfig';

const BiometricAuth = () => {
  const navigate = useNavigate();
  const auth = useAuth<Person, ClientAccount>();
  const [complete, setComplete] = useState(false);
  const account = auth?.userAccount;
  const user = auth?.user;

  useEffect(() => {
    if (
      AuthenticatorConfig.getEnabledForAccount(account) ||
      AuthenticatorConfig.getEnabledForAccountBiometric(account)
    ) {
      setComplete(true);
    }
  }, []);

  return (
    <DefaultLayout
      isHome={false}
      colorHeader="black"
      showBackButton={true}
      showFooter={false}
    >
      <div className={style.BiometricContainer}>
        <div className={style.BiometricInfo}>
          <div className={style.BiometricInner}>
            <h1>Please set up two-way authentication</h1>
            <div className={style.BiometricUser}>
              <img src="/images/male_user.png" />
            </div>
          </div>
          <p>
            To access all provider features you are required to set up two way
            authentication.
          </p>
          <div className={style.BiometricImageContainer}>
            <EnableAuthenticatorButton
              onClick={() => navigate(ROUTES.setupGoogleAuthenticator.path)}
            />
          </div>
        </div>
      </div>
      {/* Modal Configuration Complete */}
      {complete && (
        <div className={style.modalContainer}>
          <div className={style.modalOverlay}></div>
          <div className={style.modalContent}>
            <img src={'/images/shieldIcon.png'} />
            <h4>Configuration Completed</h4>
            <p>
              Hi, {user?.givenName} You have successfully set up two-way
              authentication. You can now access all provider features.
            </p>
            <Button
              className={style.buttonPrimary}
              onClick={() => setComplete(false)}
            >
              Close
            </Button>
          </div>
        </div>
      )}
    </DefaultLayout>
  );
};

export default BiometricAuth;
