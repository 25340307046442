export function convertToLocalDate(dateString: string): string
{
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  const localDate = new Date(dateString).toLocaleDateString('en-US',options);
  return localDate;
}

export function getFormatTime(timeString: string): string
{
  const optionsTime: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
  };

  const formattedTime = new Date(timeString).toLocaleTimeString(
    'en-US',
    optionsTime,
  );
  return formattedTime;
}

// Example usage
const inputDate = '2023-09-22T20:00:00Z';
const convertedDate = convertToLocalDate(inputDate);
console.log(convertedDate); // Output: 09/22/23
