import React, {useEffect, useState} from 'react';
import './Profile.scss';
import style from './Profile.scss.json';
import {Person} from 'profile-plus/lib/shapes/Person';
import {ClientAccount} from '../../src/shapes/ClientAccount';
import {useNavigate} from 'react-router-dom';
import {useAuth} from 'lincd-auth/lib/hooks/useAuth';
import {Tabs} from '@mui/base/Tabs';
import {TabsList} from '@mui/base/TabsList';
import {TabPanel} from '@mui/base/TabPanel';
import {Tab} from '@mui/base/Tab';
import {Button} from '../components/Button';
import {DefaultLayout} from '../layout/DefaultLayout';
import {ROUTES} from '../routes';
import AllAboutYouForm from '../components/AllAboutYouForm';
import AllAboutProviderForm from '../components/AllAboutProviderForm';
import {ProviderAccount} from '../shapes/ProviderAccount';
import {Storage} from '@capacitor/storage';
import {Organization} from 'lincd-schema/lib/shapes/Organization';
import {ShapeValuesSet} from 'lincd/lib/collections/ShapeValuesSet';
import {ProfilePictureUploader} from 'profile-plus/lib/components/ProfilePictureUploader';
import {LinkedStorage} from 'lincd/lib/utils/LinkedStorage';
import {Spinner} from '../components/Spinner';

interface ProfileProps {
  userUID?: string;
}

const ProfilePage = ({}: ProfileProps) => {
  const navigate = useNavigate();
  const auth = useAuth<Person, ClientAccount | ProviderAccount>();
  const user = auth.user;
  let account = auth.userAccount as ClientAccount;
  if (account instanceof ProviderAccount) {
    account = auth.userAccount as ProviderAccount;
  }

  const [openEdit, setOpenEdit] = useState(false);

  const toggleEdit = () => setOpenEdit((value) => !value);

  const onSignOut = () => {
    auth.signout();
    navigate(ROUTES.signin.path);
  };

  const orgs: ShapeValuesSet<Organization> = Organization.getAffiliationsOf(
    auth.user,
  );

  const [organizationsLoaded, setOrganizationsLoaded] =
    useState<boolean>(false);

  let organization = orgs.first();
  useEffect(() => {
    if (account instanceof ProviderAccount && organization) {
      LinkedStorage.loadShape(organization).then(() => {
        setOrganizationsLoaded(true);
      });
    }
  }, []);

  // if (!organizationsLoaded) return <Spinner />;

  const renderBio = (isProviderTab: boolean = false) => {
    //dont render on the client tab if this is a provider account
    if (account instanceof ProviderAccount && !isProviderTab) return null;

    if (!user.description) return null;
    //but DO render if this is a client on the client tab, or a provider on the provider tab
    return (
      <div className={style.bioDesc}>
        <p>
          {user.description}{' '}
          <img
            src="/images/profile/edit.png"
            alt="edit-page"
            className={style.editImg}
            onClick={toggleEdit}
          />
        </p>
      </div>
    );
  };

  return (
    <DefaultLayout
      isHome={false}
      colorHeader={'black'}
      showFooter={true}
      className={style.layout}
      resetTopSafeArea={true}
      title="Profile"
    >
      <div className={style.profileContainer}>
        <div className={style.backgroundImage}>
          {/* <ProfilePictureUploader
            className={style.profilePic}
            thumbnailHeight={130}
            aspectRatio={1}
          /> */}
        </div>

        <div className={style.bioProfile}>
          {/* <p className={style.hashprofile}>{`@Katherine - ${userUID}`}</p> */}
          <div className={style.nameProfileBlock}>
            <h1 className={style.nameProfile}>
              {`${
                user.givenName
                  ? user.givenName + ' ' + user.familyName
                  : account.email
              }`}
            </h1>
            {(account as ProviderAccount).isVerified && (
              <img
                className={style.verifiedIcon}
                src="/images/checkmark.png"
                alt="verified"
              />
            )}
          </div>
          <div className={style.bioBadge}>
            <p>{user.age ? user.age + ' years old' : ''}</p>
          </div>
          <Tabs defaultValue={1}>
            {account instanceof ProviderAccount && (
              <TabsList className={style.tabContainer}>
                <Tab className={style.tab} value={1}>
                  My Profile
                </Tab>
                <Tab className={style.tab} value={2}>
                  My Business Profile
                </Tab>
              </TabsList>
            )}
            <TabPanel value={1}>
              <div className={style.profileBadge}>
                {/*<ProfilePictureView*/}
                {/*  of={user.profilePicture}*/}
                {/*  className={style.profilePic}*/}
                {/*/>*/}
                <ProfilePictureUploader
                  className={style.profilePic}
                  thumbnailHeight={130}
                  aspectRatio={1}
                />
              </div>
              {!openEdit ? (
                <div className={style.bioDesc}>
                  {renderBio()}
                  {/*<h4 className={style.headingBio}>Information</h4>*/}
                  <ul className={style.bioInformation}>
                    <li>
                      <div className={style.labelBio}>
                        <img
                          src="/images/profile/male_user.png"
                          alt="first name"
                        />
                        <p>First name</p>
                      </div>
                      <div className={style.labelInput}>
                        <p>{user.givenName || '-'}</p>
                        <img
                          src="/images/profile/edit.png"
                          alt="edit-page"
                          className={style.editImg}
                          onClick={toggleEdit}
                        />
                      </div>
                    </li>
                    <li>
                      <div className={style.labelBio}>
                        <img
                          src="/images/profile/male_user.png"
                          alt="first name"
                        />
                        <p>Last name</p>
                      </div>
                      <div className={style.labelInput}>
                        <p>{user.familyName || '-'}</p>
                        <img
                          src="/images/profile/edit.png"
                          alt="edit-page"
                          className={style.editImg}
                          onClick={toggleEdit}
                        />
                      </div>
                    </li>
                    <li>
                      <div className={style.labelBio}>
                        <img
                          src="/images/profile/calendar.png"
                          alt="date of birth"
                        />
                        <p>Date of birth</p>
                      </div>
                      <div className={style.labelInput}>
                        <p>{user.birthDate?.toDateString() || '-'}</p>
                        <img
                          src="/images/profile/edit.png"
                          alt="edit-page"
                          className={style.editImg}
                          onClick={toggleEdit}
                        />
                      </div>
                    </li>
                    <li>
                      <div className={style.labelBio}>
                        <img src="/images/profile/gender.png" alt="gender" />
                        <p>Gender</p>
                      </div>
                      <div className={style.labelInput}>
                        <p>{user.gender || '-'}</p>
                        <img
                          src="/images/profile/edit.png"
                          alt="edit-page"
                          className={style.editImg}
                          onClick={toggleEdit}
                        />
                      </div>
                    </li>
                    <li>
                      <div className={style.labelBio}>
                        <img
                          src="/images/profile/whatsapp.png"
                          alt="whatsapp"
                        />
                        <p>Whatsapp number</p>
                      </div>
                      <div className={style.labelInput}>
                        <p>{user.telephone || '-'}</p>
                        <img
                          src="/images/profile/edit.png"
                          alt="edit-page"
                          className={style.editImg}
                          onClick={toggleEdit}
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              ) : (
                <AllAboutYouForm onSubmitLabel={'Save'} onSubmit={toggleEdit} />
              )}
            </TabPanel>
            {account instanceof ProviderAccount && (
              <TabPanel value={2}>
                <div className={style.profileBadge}>
                  <ProfilePictureUploader
                    property="profilePicture2"
                    thumbnailHeight={130}
                    aspectRatio={1}
                    className={style.profilePic}
                  />
                  <p>(company logo)</p>
                </div>
                {!openEdit ? (
                  <>
                    {renderBio(true)}
                    {/*In the future we will show also a business description. There will already be some in the data*/}
                    {/*{organization?.description && (*/}
                    {/*  <div className={style.bioDesc}>*/}
                    {/*    <p>{organization.description} <img*/}
                    {/*      src="/images/profile/edit.png"*/}
                    {/*      alt="edit-page"*/}
                    {/*      className={style.editImg}*/}
                    {/*      onClick={toggleEdit}*/}
                    {/*    /></p>*/}
                    {/*  </div>*/}
                    {/*)}*/}
                    {/*<h4 className={style.headingBio}>Information</h4>*/}
                    <ul className={style.bioInformation}>
                      <li>
                        <div className={style.labelBio}>
                          <img
                            src="/images/profile/office.png"
                            alt="company name"
                          />
                          <p>Company Name</p>
                        </div>
                        <div className={style.labelInput}>
                          <p>{organization.legalName || '-'}</p>
                          <img
                            src="/images/profile/edit.png"
                            alt="edit-page"
                            className={style.editImg}
                            onClick={toggleEdit}
                          />
                        </div>
                      </li>

                      <li>
                        <div className={style.labelBio}>
                          <img
                            src="/images/profile/office.png"
                            alt="company first name"
                          />
                          <p>Company First Name</p>
                        </div>
                        <div className={style.labelInput}>
                          <p>
                            {(account as ProviderAccount).businessFirstName ||
                              '-'}
                          </p>
                          <img
                            src="/images/profile/edit.png"
                            alt="edit-page"
                            className={style.editImg}
                            onClick={toggleEdit}
                          />
                        </div>
                      </li>
                      <li>
                        <div className={style.labelBio}>
                          <img
                            src="/images/profile/office.png"
                            alt="company last name"
                          />
                          <p>Company Last Name</p>
                        </div>
                        <div className={style.labelInput}>
                          <p>
                            {(account as ProviderAccount).businessLastName ||
                              '-'}
                          </p>
                          <img
                            src="/images/profile/edit.png"
                            alt="edit-page"
                            className={style.editImg}
                            onClick={toggleEdit}
                          />
                        </div>
                      </li>

                      <li>
                        <div className={style.labelBio}>
                          <img src="/images/profile/email.png" alt="email" />
                          <p>Email</p>
                        </div>
                        <div className={style.labelInput}>
                          <p>{organization.email || '-'}</p>
                          <img
                            src="/images/profile/edit.png"
                            alt="edit-page"
                            className={style.editImg}
                            onClick={toggleEdit}
                          />
                        </div>
                      </li>
                      <li>
                        <div className={style.labelBio}>
                          <img
                            src="/images/profile/website.png"
                            alt="website"
                          />
                          <p>Website</p>
                        </div>
                        <div className={style.labelInput}>
                          <p>{organization.url || '-'}</p>
                          <img
                            src="/images/profile/edit.png"
                            alt="edit-page"
                            className={style.editImg}
                            onClick={toggleEdit}
                          />
                        </div>
                      </li>
                      <li>
                        <div className={style.labelBio}>
                          <img src="/images/profile/phone.png" alt="phone" />
                          <p>Business Phone</p>
                        </div>
                        <div className={style.labelInput}>
                          <p>{organization.telephone || '-'}</p>
                          <img
                            src="/images/profile/edit.png"
                            alt="edit-page"
                            className={style.editImg}
                            onClick={toggleEdit}
                          />
                        </div>
                      </li>
                      <li>
                        <div className={style.labelBio}>
                          <img src="/images/profile/fax.png" alt="phone" />
                          <p>Fax Number</p>
                        </div>
                        <div className={style.labelInput}>
                          <p>{organization.faxNumber || '-'}</p>
                          <img
                            src="/images/profile/edit.png"
                            alt="edit-page"
                            className={style.editImg}
                            onClick={toggleEdit}
                          />
                        </div>
                      </li>
                    </ul>
                  </>
                ) : (
                  <AllAboutProviderForm
                    onClick={toggleEdit}
                    onSubmitLabel={'Save'}
                  />
                )}
              </TabPanel>
            )}
          </Tabs>
          {!openEdit && (
            <Button
              variant="contained"
              color="primary"
              fullWidth={false}
              onClick={onSignOut}
              className={style.buttonSignOut}
            >
              Sign Out
            </Button>
          )}
        </div>
      </div>
    </DefaultLayout>
  );
};

export default ProfilePage;
