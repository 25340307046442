import {Literal, NamedNode} from 'lincd/lib/models';
import {linkedShape} from '../package';
import {emergentLives} from '../ontologies/emergent-lives';
import {UserAccount} from 'lincd-sioc/lib/shapes/UserAccount';
import {objectProperty,literalProperty} from 'lincd/lib/utils/ShapeDecorators';
import {Server} from 'lincd-server-utils/lib/utils/Server';
import {Boolean} from 'lincd-xsd/lib/shapes/Boolean';
import {ImageObject} from 'lincd-schema/lib/shapes/ImageObject';

@linkedShape
export class ClientAccount extends UserAccount
{
  static targetClass: NamedNode = emergentLives.ClientAccount;

  @literalProperty({
    path: emergentLives.twoFactorEnabled,
    dataType: Boolean.targetClass,
  })
  get twoFactorEnabled(): boolean {
    return Boolean.isTrue(
      this.getOne(emergentLives.twoFactorEnabled) as Literal,
    );
  }

  set twoFactorEnabled(val: boolean) {
    this.overwrite(
      emergentLives.twoFactorEnabled,
      val ? Boolean.true() : Boolean.false(),
    );
  }
  
  @objectProperty({
    path: emergentLives.qrCode,
    shape: ImageObject,
    maxCount: 1,
  })
  get qrCode(): ImageObject
  {
    return this.getOneAs(emergentLives.qrCode,ImageObject);
  }

  set qrCode(val: ImageObject)
  {
    this.overwrite(emergentLives.qrCode,val.node);
  }

  getPersonalQRCode()
  {
    return Server.call(this,'getPersonalQRCode');
  }

  getConnections()
  {
    return Server.call(this,'getConnections');
  }

  getConnectionTo(person)
  {
    return Server.call(this,'getConnectionTo',person);
  }
}
