import React, {useEffect, useState} from 'react';
import {DefaultLayout} from '../layout/DefaultLayout';
import {useAuth} from 'lincd-auth/lib/hooks/useAuth';
import {ROUTES} from '../routes';
import {useNavigate} from 'react-router-dom';
import './Signin.scss';
import style from './Signin.scss.json';
import {Divider} from '../components/Divider';
import {Person} from 'profile-plus/lib/shapes/Person';
import {ClientAccount} from '../shapes/ClientAccount';
import {TermsAndConditions} from '../components/TermsAndConditions';
import {Spinner} from '../components/Spinner';
import {SigninWithGoogleButton} from 'lincd-capacitor/lib/components/SigninWithGoogleButton';
import {SigninWithAppleButton} from 'lincd-capacitor/lib/components/SigninWithAppleButton';
import {SigninWithPasswordForm} from 'lincd-auth/lib/components/SigninWithPasswordForm';
import {AuthenticationResponse} from 'lincd-auth/lib/types/auth';

export default function Signin() {
  const [loggingIn, setLoggingIn] = useState<boolean>(false);
  const auth = useAuth<Person, ClientAccount>();
  let user = auth.user;
  const account = auth.userAccount;

  const navigate = useNavigate();

  useEffect(() => {
    if (account) {
      navigate(ROUTES.allAboutYou.path);
    }
  }, []);

  // callback after successful login
  const onLoggedIn = (response: AuthenticationResponse) => {
    if (response) {
      user = response.user as Person;
      if (user.profileSetupCompleted) {
        navigate(ROUTES.events.path);
      } else {
        navigate(ROUTES.allAboutYou.path);
      }
    }
  };

  return (
    <>
      {loggingIn ? (
        <Spinner />
      ) : (
        <DefaultLayout
          isHome={false}
          colorHeader="black"
          showHeader={false}
          // className={style.layout}
          showFooter={false}
          resetTopSafeArea={true}
        >
          <div className={style.Root}>
            <div
              className={style.background}
              style={{backgroundImage: "url('images/WeddingCeleb@2x.webp')"}}
            />
            <div className={style.content}>
              <div className={style.wrapper}>
                <div className={style.logo}>
                  <img src="images/EL-logo.svg" alt="emergent lives logo" />
                </div>
                <div className={style.magicLoginButton}>
                  {/* <p>Sign in with email</p> */}
                  <SigninWithPasswordForm
                    onLoggedIn={onLoggedIn}
                    showEmailIcon={false}
                    buttonClassName={style.emailButton}
                    onLoadingChange={setLoggingIn}
                  />
                </div>
                <Divider text="or sign in with" />
                <div className={style.buttons}>
                  <SigninWithGoogleButton
                    className={style.oauthButton}
                    label="Google"
                    onCallback={onLoggedIn}
                    onLoadingChange={setLoggingIn}
                  />
                  <SigninWithAppleButton
                    className={style.oauthButton}
                    label="Apple"
                    onCallback={onLoggedIn}
                    onLoadingChange={setLoggingIn}
                  />
                </div>
                <TermsAndConditions />
              </div>
            </div>
          </div>
        </DefaultLayout>
      )}
    </>
  );
}
