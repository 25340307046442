import React, {useEffect} from 'react';
import style from './AllAboutYou.scss.json';
import './AllAboutYou.scss';
import {useAuth} from 'lincd-auth/lib/hooks/useAuth';
import {Person} from 'profile-plus/lib/shapes/Person';
import {useNavigate} from 'react-router-dom';
import {ROUTES} from '../routes';
import {ClientAccount} from '../shapes/ClientAccount';
import {DefaultLayout} from '../layout/DefaultLayout';
import AllAboutYouForm from '../components/AllAboutYouForm';
import {ProviderAccount} from '../shapes/ProviderAccount';
import {ProfilePictureUploader} from 'profile-plus/lib/components/ProfilePictureUploader';

export default function AllAboutYou() {
  const auth = useAuth<Person, ClientAccount>();
  const navigate = useNavigate();

  useEffect(() => {
    // skip profile input if user already completed the profile
    if (auth.user.profileSetupCompleted) {
      navigate(ROUTES.events.path);
    }
  }, []);

  const onContinue = () => {
    if (auth.userAccount instanceof ProviderAccount) {
      //for providers we do a few more steps
      navigate(ROUTES.biometric.path);
    } else {
      //for clients the profile setup is now complete
      auth.user.profileSetupCompleted = true;
      navigate(ROUTES.events.path);
    }
  };

  return (
    <DefaultLayout
      isHome={false}
      colorHeader={'black'}
      showHeader={false}
      showFooter={false}
      // className={style.layout}
    >
      <div className={style.wrapper}>
        <h1 className={style.headline}>All About You</h1>
        <p className={style.subtitle}>
          Please tell us more about you, so we can reach you at anytime.
        </p>
        <div>
          {/*<div className={style.uploader}>*/}
          {/*<div className={style.uploader__photo}>*/}
          {/*  <div className={style.uploader__profile}>*/}
          {/*    <Avatar*/}
          {/*      of={user}*/}
          {/*      size="medium"*/}
          {/*      overwriteSource={profilePicture}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*  <img*/}
          {/*    src="/images/checkmark.png"*/}
          {/*    alt="checkmark"*/}
          {/*    className={style.uploader__checkmark}*/}
          {/*  />*/}
          {/*</div>*/}
          <p>Profile Picture</p>
          <ProfilePictureUploader
            // thumbnailHeight?: number;
            // thumbnailWidth?: number;
            thumbnailHeight={200}
            // componentHeight={300}
            aspectRatio={1}

            // onUpdate={onProfilePictureUploaded}
            // renderAction={
            //   <Button
            //     variant="outlined"
            //     className={style.uploaderButton}
            //     startIcon={
            //       <img
            //         src="/images/uploadToCloud.png"
            //         alt="upload to cloud"
            //         width="35"
            //         height="auto"
            //       />
            //     }
            //   >
            //     Upload Photo
            //   </Button>
            // }
          />
          {/*</div>*/}
        </div>
        <AllAboutYouForm onSubmit={onContinue} onSubmitLabel={'Continue'} />
      </div>
    </DefaultLayout>
  );
}
